// Do not use this component for other fields at the moment.
// I thought it would be a generic component for editing
// input profile fields but it is too opinionated

import { useState, useEffect } from "react";
import { FormInput } from "./form/FormInput";
import { MainInactiveButton } from "./buttons/MainInactiveButton";
import { MainActionButton } from "./buttons/MainActionButton";
import ReactFlagsSelect from "react-flags-select";
import { sendOTPMessage, verifyOTP } from "../api/auth";
import { userStore } from "../stores";
import { addPhoneNumber, checkPhoneNumber } from "../api/auth";
import { isPhone } from "../validations/validator";
export const PhoneInput = ({ setPhoneNumberVerified, setPhoneNumber }) => {
  const user = userStore((state) => state.user);
  const [phoneError, setPhoneError] = useState([]);
  const [phone, setPhone] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);
  const [checkingVerificationCode, setCheckingVerificationCode] =
    useState(false);
  const [OtpError, setOtpError] = useState([]);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const storeUser = userStore((state) => state.storeUser);

  const handleChange = (input) => {
    setPhone(input.target.value);

    const validPhone = isPhone(input.target.value);

    if (validPhone && validPhone.valid) {
      setPhone(validPhone.phone);
      setPhoneError([false, ""]);
    } else {
      setPhoneError([true, "Invalid Phone Number."]);
    }
  };

  const sendOTPVerification = () => {
    setVerifyingPhone(true);

    sendOTPMessage({
      phone: "+" + phone,
    }).then((response) => {
      setVerifyingPhone(false);
      if (response && response.status === 200) {
        setVerificationSent(true);
      } else {
        console.log(response);
        setPhoneError([true, "Something Went Wrong"]);
        setVerifyingPhone(false);
      }
    });
  };
  const handleChangeVerifyCode = (input) => {
    if (input.target.value && input.target.value.length === 4) {
      checkVerificationCode(input);
    }
  };

  const checkVerificationCode = (inp) => {
    setCheckingVerificationCode(true);
    console.log(phone);
    const code = Number(inp.target.value);

    verifyOTP({
      phone: "+" + phone,
      otp: code,
    }).then((response) => {
      if (response.status === 200) {
        setPhoneVerified(true);
        setPhoneNumberVerified(true);
        setPhoneNumber("+" + phone);
        setOtpError([]);
        // handlePhoneSubmit();
        setCheckingVerificationCode(false);
      } else {
        console.log(response);
        setOtpError([true, "Incorrect verification Code"]);
        setCheckingVerificationCode(false);
      }
    });
  };
  // const handlePhoneSubmit = () => {
  //   setLoadingPhone(true);

  //   addPhoneNumber({ phone: "+" + phone }).then((response) => {
  //     setLoadingPhone(false);
  //     if (response && response.status === 200) {
  //       const dataChanged = response.data.phoneNumber;
  //       const newUser = { ...user, ...dataChanged };
  //       storeUser(newUser);
  //     } else {
  //       setPhoneError(true, response.data.message);
  //     }
  //   });
  // };
  return (
    <div className="w-full flex flex-col  mb-2">
      {(!verificationSent && !phoneVerified && (
        <div className="w-full lg:mr-4">
          <div>
            {phoneError[0] && (
              <div className="text-red-500 text-sm">{phoneError[1]}</div>
            )}
          </div>
          <div className="flex space-x-2 items-center">
            <FormInput
              label={"Phone Number"}
              type={"text"}
              name={"phone"}
              placeholder={"Phone"}
              onchangeHandler={handleChange}
              defaultValue={""}
            />
          </div>
        </div>
      )) ||
        (verificationSent && !phoneVerified && (
          <div className="w-full mr-4">
            <div className="flex flex-col  gap-x-3 justify-center ">
              {OtpError[0] && (
                <div className="text-red-500 text-sm">{OtpError[1]}</div>
              )}
              <FormInput
                label={"Verification code"}
                type={"number"}
                name={"code"}
                placeholder={1234}
                onchangeHandler={handleChangeVerifyCode}
              />
              <div className="w-1/2 lg:w-2/3 mt-2">
                <MainActionButton
                  text="Verify code"
                  onClickHandler={checkVerificationCode}
                  loaderVisibility={checkingVerificationCode || loadingPhone}
                  className="bg-gradient-to-r from-dark-yellow to-light-yellow"
                />
              </div>
            </div>
          </div>
        )) ||
        (!phoneError[0] && phoneVerified && (
          <div className="grow p-3 border rounded-md tracking-wider bg-gray-50">
            {phone}
          </div>
        ))}
      <div className="w-full flex items-center  p-2">
        {(phoneError[0] || phoneError.length == 0) && (
          <div className="w-2/3">
            <MainInactiveButton info="Verify phone" />
          </div>
        )}
        {phoneError.length !== 0 &&
          !phoneError[0] &&
          !phoneVerified &&
          !verificationSent && (
            <div className="w-2/3">
              <MainActionButton
                text="Verify phone"
                onClickHandler={sendOTPVerification}
                loaderVisibility={verifyingPhone}
                className="bg-gradient-to-r from-dark-yellow to-light-yellow"
              />
            </div>
          )}
      </div>
    </div>
  );
};
