import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router-dom";
import {
  userStore,
  useTippingMpesa,
  useTippingPaypal,
} from "../../../../../../../stores";
import { likeContent } from "../../../../../../../api/auth";
import { AiFillHeart } from "react-icons/ai";
import { IoMdShareAlt } from "react-icons/io";
import { MdHowToVote } from "react-icons/md";
import { LoadingModal } from "../../../../../../modals/loadingModal";
import { usePlaylist } from "../../../../../../../stores";
import { Link } from "react-router-dom";
import defaultImage from "../../../../../../../assets/defaultSong.webp";

const Login = lazy(() => import("../../../../../../auth/login"));
const SignUp = lazy(() => import("../../../../../../auth/signup"));
const VotingModal = lazy(() => import("../../../../../../modals/votingModal"));
const ShareModal = lazy(() => import("../../../../../../modals/shareModal"));
const TippingPaymentModal = lazy(() =>
  import("../../../../../../modals/tippingPaymentModal")
);
const PaypalTippingModal = lazy(() =>
  import("../../../../../../modals/paypalTippingModal")
);

export const SectionThreeCard = ({ content }) => {
  const baseUrl = `${
    (window.location.href.includes("localhost") &&
      "http://localhost:3000/player/browse") ||
    (window.location.href.includes("dev.bloowafrica") &&
      "https://dev.bloowafrica.com/player/browse") ||
    "https://bloowafrica.com/player/browse"
  }`;
  const [showMenu, setShowMenu] = useState(false);
  const [liked, setLiked] = useState();
  const [likesCount, setLikesCount] = useState();
  const [loginText, setLoginText] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const updatePlaylist = usePlaylist((state) => state.setPlaylist);
  const playlist = usePlaylist((state) => state.playlist);
  const setCurrentlyPlaying = usePlaylist((state) => state.setCurrentlyPlaying);
  const currentlyPlaying = usePlaylist((state) => state.currentlyPlaying);
  const setIsPlaying = usePlaylist((state) => state.setIsPlaying);
  const mpesa = useTippingMpesa((state) => state.mpesa);
  const user = userStore((state) => state.user);
  const paypal = useTippingPaypal((state) => state.paypal);
  const setMpesa = useTippingMpesa((state) => state.setMpesa);
  const history = useHistory();
  useEffect(() => {
    setLikesCount(content?.likes?.length);
    if (user) {
      setLiked(
        user?.likedContent?.find((like) => {
          return like.contentId == content._id;
        })
      );
    }
  }, [user]);

  const likingContent = (contentId) => {
    if (user) {
      likeContent({ contentId: contentId }).then((response) => {});
      if (liked) {
        setLiked(!liked);
        setLikesCount(likesCount - 1);
      } else {
        setLiked(!liked);
        setLikesCount(likesCount + 1);
      }
    } else {
      setShowSignIn(true);
      setLoginText("Please authenticate to like a content.");
    }
  };
  const showMoreMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeVotingHandler = () => {
    setShowVotingModal(false);
  };
  return (
    <>
      <div className="flex  w-full flex-col p-2  rounded  hover:border-2 hover:transform hover:scale-110 ease-in-out duration-300">
        <div
          className="flex w-full justify-between "
          onClickCapture={() => {
            updatePlaylist(content);
            setCurrentlyPlaying(0);
            setIsPlaying(true);
          }}
        >
          <div className="flex w-full justify-between gap-x-1 items-center">
            <div className="w-16 h-16 flex-shrink-0">
              <Link to={`/player/browse/artist/${content?.userId?._id}`}>
                <img
                  data-src={
                    content.thumbnailPath
                      ? ((content?.thumbnailPath.includes("cloudinary") ||
                          !content?.thumbnailPath.includes("prod")) &&
                          defaultImage) ||
                        content?.thumbnailPath
                      : content?.albumId?.albumBackgroundPath
                  }
                  className="w-16 h-16 object-cover rounded-lg lazyload"
                  alt=""
                />
              </Link>
            </div>
            <div className="flex flex-col w-2/5 pl-2 overflow-x-hidden">
              <div
                className={
                  playlist[currentlyPlaying]?._id == content?._id
                    ? " font-bold text-bloow-blue pb-1 line-clamp-1"
                    : " font-bold text-white pb-1 line-clamp-1"
                }
              >
                {content.contentName.replace(".mp3", "")}
              </div>
              <div className=" text-gray-400  text-xs hover:underline ">
                <Link to={"/player/browse/artist/" + content?.userId?._id}>
                  {content?.userId?.names ||
                    content?.userId?.email.split("@")[0]}
                </Link>
              </div>
            </div>
            <div className=" px-2 ">
              <div className="flex items-center gap-x-4 ">
                <div className="">
                  <AiFillHeart
                    className={(liked && "text-red-500") || "text-white"}
                    size={18}
                    onClick={() => {
                      likingContent(content._id);
                    }}
                  />
                  <div className="text-gray-400  text-xss">like</div>
                </div>
                <div className="">
                  <MdHowToVote
                    className=" text-white"
                    size={19}
                    onClick={() => {
                      setShowVotingModal(true);
                    }}
                  />
                  <div className=" text-xss text-gray-400 ">Tip</div>
                </div>
                <div>
                  <IoMdShareAlt
                    className=" text-white"
                    size={20}
                    onClick={() => {
                      setShowShareModal(true);
                    }}
                  />
                  <div className="text-gray-400  text-xss">share</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mpesa && (
        <Suspense fallback={<LoadingModal />}>
          <VotingModal
            closeHandler={() => setMpesa(false)}
            contentId={content._id}
          />
        </Suspense>
      )}

      {showVotingModal && (
        <Suspense fallback={<LoadingModal />}>
          <TippingPaymentModal
            id={content?.userId?._id}
            closeHandler={closeVotingHandler}
          />
        </Suspense>
      )}
      {paypal && (
        <Suspense fallback={<LoadingModal />}>
          <PaypalTippingModal artistId={content?.userId?._id} />
        </Suspense>
      )}
      {showShareModal && (
        <Suspense fallback={<LoadingModal />}>
          <ShareModal
            closeHandler={() => {
              setShowShareModal(false);
            }}
            imageUrl={content.thumbnailPath}
            name={content.contentName}
            url={`${baseUrl}/content/${content._id}`}
          />
        </Suspense>
      )}
      {!user && showSignUp && (
        <Suspense fallback={<LoadingModal />}>
          <SignUp
            setShowSignUp={setShowSignUp}
            setShowSignIn={setShowSignIn}
            userType={"basic"}
          />
        </Suspense>
      )}
      {!user && showSignIn && (
        <Suspense fallback={<LoadingModal />}>
          <Login
            setShowSignIn={setShowSignIn}
            setShowSignUp={setShowSignUp}
            loginText={loginText}
          />
        </Suspense>
      )}
    </>
  );
};

export const SectionThreeCardLoading = () => {
  return (
    <>
      <div className="flex  w-full justify-between p-2 rounded border">
        <div className="flex justify-between">
          <div className="w-full flex gap-x-1 ">
            <div className="w-20 h-20 rounded bg-gray-300 animate-pulse flex-shrink-0"></div>
            <div className="w-full flex flex-col pt-2 gap-y-2">
              <div className="w-28 h-3 bg-gray-300 rounded animate-pulse"></div>
              <div className="w-24 h-2 bg-gray-300 rounded animate-pulse"></div>
            </div>
          </div>
        </div>
        <div className="flex justify-between px-2 py-1">
          <div className="flex  gap-x-4 mt-1">
            <div className="flex items-center">
              <div>
                <AiFillHeart className=" text-gray-500" size={18} />
                <div className=" text-xss text-gray-400">like</div>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <MdHowToVote className=" text-gray-500" size={19} />
                <div className=" text-xss text-gray-400">Tip</div>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <IoMdShareAlt className=" text-gray-500" size={20} />
                <div className=" text-xss text-gray-400">share</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
