import axios from 'axios';

const api = axios.create({
    
})

export const getClientInfoFromIp = async () => {

    return await api.get('https://ipapi.co/json').then((response) => response).catch((error) => {
        return error.response
    })
}
