import { Suspense, useEffect, lazy, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar } from "./components/navbar";
import Sidebar from "./components/navigation/sidebar";
import { MusicPlayer } from "../../../components/music/musicPlayer";
import { Switch, Route } from "react-router-dom";
import BookArtist from "../../../desktop/pages/player/components/bookArtist/bookArtist";
import UserBookingsPage from "../../../desktop/pages/player/components/bookArtist/userBookingsPage";
import ArtistBookingsPage from "../../../desktop/pages/player/components/bookArtist/artistBookingsPage";
import { Spinner } from "../../../components/utils/spinningLoader";
import Home from "./components/home";

const Player = () => {
  const Library = lazy(() => import("./components/library"));
  const Browse = lazy(() => import("./components/browse"));
  const Account = lazy(() => import("./components/account"));
  const Store = lazy(() => import("./components/store"));

  const Queue = lazy(() => import("./components/queue"));
  const Premium = lazy(() => import("./components/premium/premium"));
  const PurchusedContent = lazy(() =>
    import("./components/premium/purchasedContent")
  );
  const Merchandise = lazy(() =>
    import("../../../desktop/pages/player/components/merchandise/merchandise")
  );
  const PremiumAlbum = lazy(() => import("./components/premium/premiumAlbum"));

  const [showPlayer, setShowPlayer] = useState();

  const history = useHistory();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    if (/^\/player\/merchandise\b[\/\w|\d]*/g.test(history.location.pathname)) {
      setShowPlayer(false);
    } else {
      setShowPlayer(true);
    }
  }, [location]);

  return (
    <div className="flex  w-screen bg-midnight h-screen fixed  ">
      <div className=" h-full   overflow-y-scroll  no-scrollbar">
        <Sidebar />
      </div>

      <div
        className="w-full h-full   overflow-y-scroll  no-scrollbar"
        ref={ref}
      >
        <Navbar />

        <Switch>
          <Route exact path={"/player" || "/player"}>
            <Home />
          </Route>
          <Route exact path="/player/library">
            <Suspense fallback={<Spinner />}>
              <Library />
            </Suspense>
          </Route>
          <Route exact path="/player/premium">
            <Suspense fallback={<Spinner />}>
              <Premium />
            </Suspense>
          </Route>
          <Route path="/player/merchandise">
            <Suspense fallback={<Spinner />}>
              <Merchandise />
            </Suspense>
          </Route>
          <Route exact path="/player/premium/album/:albumId">
            <Suspense fallback={<Spinner />}>
              <PremiumAlbum />
            </Suspense>
          </Route>
          <Route exact path="/player/premium/purchased">
            <Suspense fallback={<Spinner />}>
              <PurchusedContent />
            </Suspense>
          </Route>
          <Route exact path="/player/book/bookings/artist">
            <Suspense fallback={<Spinner />}>
              <ArtistBookingsPage />
            </Suspense>
          </Route>
          <Route path="/player/browse">
            <Suspense fallback={<Spinner />}>
              <Browse />
            </Suspense>
          </Route>
          <Route exact path="/player/book/bookings">
            <UserBookingsPage />
          </Route>
          <Route exact path="/player/book">
            <BookArtist />
          </Route>

          <Route path="/player/queue">
            <Suspense fallback={<Spinner />}>
              <Queue />
            </Suspense>
          </Route>
          <Route exact path="/player/store">
            <Suspense fallback={<Spinner />}>
              <Store />
            </Suspense>
          </Route>
          <Route exact path="/player/account">
            <Suspense fallback={<Spinner />}>
              <Account />
            </Suspense>
          </Route>
        </Switch>

        {showPlayer && (
          <div className="fixed w-full bottom-0 left-0 rounded-t-md z-20 ">
            <div className="w-full bg-black pt-2 text-white">
              <MusicPlayer
                openQueue={() => {
                  history.push("/player/queue");
                }}
                autoPlay={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Player;
