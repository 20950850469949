export const isEmail = (email) => {
  const regEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(regEx)) return true;
  else return false;
};
export const isTwitter = (twitter) => {
  const regEx = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
  if (twitter.match(regEx)) return true;
  else return false;
};
export const isFacebook = (facebook) => {
  const regEx = /http(?:s)?:\/\/(?:www\.)?facebook\.com\/([a-zA-Z0-9_]+)/;
  if (facebook.match(regEx)) return true;
  else return false;
};
export const isInstagram = (instagram) => {
  const regEx = /http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/;
  if (instagram.match(regEx)) return true;
  else return false;
};
export const isLinkedin = (linkedin) => {
  const regEx = /http(?:s)?:\/\/(?:www\.)?linkedin\.com\/([a-zA-Z0-9_]+)/;
  if (linkedin.match(regEx)) return true;
  else return false;
};
export const isYoutube = (youtube) => {
  const regEx = /http(?:s)?:\/\/(?:www\.)?youtube\.com\/([a-zA-Z0-9_]+)/;
  if (youtube.match(regEx)) return true;
  else return false;
};
export const isPassword = (password) => {
  if (password.length < 6) return false;
  else return true;
};
export const isPhone = (phone) => {
  if (phone[0] == "2" && phone?.length !== 11)
    return { valid: false, phone: phone };
  else if (phone[0] == "+" && phone?.length !== 12)
    return { valid: false, phone: phone };
  else if (phone[0] == "0" && phone?.length !== 9)
    return { valid: false, phone: phone };
  else if (phone[0] === "+" && phone?.length === 12) {
    return { valid: true, phone: parseInt(phone.slice(1)) };
  } else if (phone[0] === "0" && phone?.length === 9) {
    const validPhone = "254" + phone.slice(1);
    return { valid: true, phone: parseInt(validPhone) };
  } else if (phone[0] === "2" && phone?.length === 11) {
    return { valid: true, phone: parseInt(phone) };
  }
};
