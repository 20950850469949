import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArtistCard, ArtistCardLoading } from "../../../cards/artistCards";
import { getBookableArtists } from "../../../../../api/auth";
import { FormSelect } from "../../../../form/FormSelect";
function ArtistList() {
  const [loading, setLoading] = useState();
  const [artists, setArtists] = useState([]);
  const [talent, setTalent] = useState("artists");
  useEffect(() => {
    const getContent = async () => {
      setLoading(true);
      const res = await getBookableArtists();
      setLoading(false);
      if (res && res.status == 200) {
        setArtists(res.data.artists);
      }
    };
    getContent();
  }, []);
  return (
    <div>
      <div className="w-full flex items-center pb-4 justify-between">
        <div className="  flex space-x-4 items-center flex-wrap">
          <SelectTalent setTalent={setTalent} talent={talent} />
          <div className="  flex space-x-4 md:pt-0 pt-4">
            <Link
              to={"/player/book"}
              className=" text-green   cursor-pointer hover:text-white"
            >
              Talents
            </Link>
            <Link
              to={"/player/book/bookings"}
              className="  text-white cursor-pointer hover:text-green"
            >
              Your Bookings
            </Link>
          </div>
        </div>
        <div className="flex items-center">
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${"text-gray-300"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${"text-gray-300"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </div>
      {talent == "artists" && (
        <div className="">
          {loading && <ArtistListLoading />}
          <div className="w-full   flex   flex-wrap justify-between md:justify-start no-scrollbar gap-x-3 gap-y-4 mt-3 overflow-x-scroll ">
            {!loading &&
              artists.length > 0 &&
              artists.map((content) => (
                <ArtistCard content={content} book={true} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
const ArtistListLoading = () => {
  return (
    <div className="w-full    flex justify-evenly flex-wrap overflow-x-scroll  no-scrollbar gap-x-3 gap-y-4 mt-3">
      {Array(12)
        .fill(1)
        .map((el, index) => {
          return <ArtistCardLoading />;
        })}
    </div>
  );
};

const SelectTalent = ({ setTalent, talent }) => {
  const handleChange = (input) => {
    setTalent(input.target.value);
  };
  return (
    <div className=" bg-white rounded-lg w-auto p-1 px-2 ">
      <div className="">
        <FormSelect
          label={""}
          name={"role"}
          dataTestId={"role-input"}
          items={[
            { name: "Artists", value: "artists" },
            { name: "DJs", value: "djs" },
            { name: "MCs", value: "mcs" },
            { name: "Dancers", value: "dancers" },
            { name: "Vixens", value: "vixens" },
          ]}
          onchangeHandler={handleChange}
          className="text-black"
          other={false}
          light={false}
        />
      </div>
    </div>
  );
};

export default ArtistList;
