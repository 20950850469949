import Navbar from "../../../../../components/utils/player/desktop/premium/navbar";
import { userStore } from "../../../../../stores";
import ArtistList from "../../../../../components/utils/player/desktop/bookArtist/ArtistList";
const BookArtist = ({ play }) => {
  const user = userStore((state) => state.user);

  return (
    <div className="w-full mt-4 flex justify-center my-4 pb-20">
      <div className="w-11/12 md:pt-0 pt-6 flex flex-col gap-y-8 gap-x-4 ">
        <Navbar />
        <ArtistList />
      </div>
    </div>
  );
};

export default BookArtist;
