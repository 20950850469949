import React from "react";
import { Link } from "react-router-dom";
export function GenreCard({ content }) {
  return (
    <>
      <Link to={`/player/browse/genre/${content.category}`}>
        <div className=" h-full rounded-lg  w-40  flex-shrink-0   pt-2  relative">
          <img data-src={content.imagePath} className="w-80 h-full lazyload" alt="" />
          <div className="  text-white absolute font-black bottom-0 right-0 pr-2 pb-2">
            {content.category}
          </div>
        </div>
      </Link>
    </>
  );
}
export function LoadingGenre() {
  return (
    <div className="w-40 h-full rounded-lg bg-gray-300 animate-pulse flex-shrink-0"></div>
  );
}
