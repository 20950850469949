import Navbar from "../../../../../components/utils/player/desktop/premium/navbar";
import { userStore } from "../../../../../stores";
import ArtistBookings from "../../../../../components/utils/player/desktop/bookArtist/ArtistBookings";
const ArtistBookingsPage = ({ play }) => {
  const user = userStore((state) => state.user);

  return (
    <div className="w-full mt-4 flex justify-center my-4 pb-20">
      <div className="w-11/12 flex flex-col gap-y-8 gap-x-4 ">
        <ArtistBookings />
      </div>
    </div>
  );
};

export default ArtistBookingsPage;
