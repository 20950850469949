import { MusicLoader } from '../utils/musicLoaderSm'

export const MainInactiveButton = ({ info, handleClick, loading, styles }) => {
  return (
    <div className={`border-2 px-4 py-2 border-gray-500  rounded-full w-auto flex justify-center cursor-pointer bg-white text-sm ${styles}`} onClick={handleClick} data-testid={"submit"}>
      <div className="">
        {
          loading ?
            <MusicLoader />
            :
            info
        }
      </div>
    </div>
  )
}
