import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
function PlayerNavbar() {
  const [navigation, setNavigation] = useState();
  const location = useLocation();


  useEffect(() => {
    if (/^\/player\b\/*$/g.test(location.pathname)) {
      setNavigation("home");
    } else if (/^\/player\/browse\b[\/\w|\d]*/g.test(location.pathname)) {
      setNavigation("genre");
    } else if (/^\/player\/podcast\b[\/\w|\d]*/g.test(location.pathname)) {
      setNavigation("podcast");
    }
    
  }, [location]);
  return (
    <div className="w-full pb-4 pt-10 bg-gradient-to-b from-green to-midnight ">
      <div className=" text-2xl font-black py-2 text-center capitalize text-white">
        {navigation}
      </div>
      <div className=" flex justify-evenly pt-2 pb-8 text-white text-sm px-2">
        <Link to={"/player"}>
          <div className="">
            OVERVIEW
            {navigation == "home" && (
              <div
                className="w-16 py-0.5  rounded-lg mx-auto"
                style={{
                  background: `linear-gradient(to right ,${"#2FAFF2"}, ${"#695FEF"})`,
                }}
              ></div>
            )}
          </div>
        </Link>
        <Link to={"/player/browse"}>
          <div className="">
            GENRE & MOODS
            {navigation == "genre" && (
              <div
                className="w-16 py-0.5  rounded-lg mx-auto"
                style={{
                  background: `linear-gradient(to right ,${"#2FAFF2"}, ${"#695FEF"})`,
                }}
              ></div>
            )}
          </div>
        </Link>

        <div className="">PODCASTS</div>
      </div>
    </div>
  );
}

export default PlayerNavbar;
