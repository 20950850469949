export const FormInput = ({
  label,
  type,
  name,
  placeholder,
  onchangeHandler,
  dataTestId,
  defaultValue,
  required,
  light,
  autoComplete,
  className,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={`text-xs ${
          light === false ? "font-medium" : "font-semibold"
        }`}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        className={`w-full rounded-md  border focus:outline-none focus:ring-0 focus:border-bloow-blue ${
          className && className
        }`}
        placeholder={placeholder}
        required={required}
        onChange={onchangeHandler}
        data-testid={dataTestId}
        defaultValue={defaultValue}
      />
    </div>
  );
};
