import React, { useState, useEffect, Suspense, lazy, useRef } from "react";
import { RiUserFollowFill } from "react-icons/ri";
import { followArtist } from "../../../api/auth";
import { userStore } from "../../../stores";
import { LoadingModal } from "../../modals/loadingModal";
import { Link } from "react-router-dom";

import defaultArtist from "../../../assets/defaultArtist.jpg";
import blurredArtist from "../../../assets/blurredArtist.jpg";
const Login = lazy(() => import("../../auth/login"));
const SignUp = lazy(() => import("../../auth/signup"));

export const ArtistCard = ({ content, premium, book }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [followersCount, setFollowersCount] = useState(0);
  const [userFollowing, setUserFollowing] = useState();
  const [artistFollowers, setArtistFollowers] = useState();
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [loginText, setLoginText] = useState("");
  const user = userStore((state) => state.user);
  const ref = useRef();
  UseOnClickOutside(ref, () => setShowMenu(false));
  useEffect(() => {
    if (content.followers) {
      setFollowersCount(content.followers.length);
      if (user) {
        setUserFollowing(
          content.followers.find((follower) => {
            return follower.userId == user._id;
          })
        );
      }
    }
  }, [user, content]);
  const userFollowArtist = () => {
    if (user) {
      if (userFollowing) {
        setFollowersCount(followersCount - 1);
        setUserFollowing(!userFollowing);
      } else if (!userFollowing) {
        setFollowersCount(followersCount + 1);
        setUserFollowing(!userFollowing);
      }
      followArtist({ artistId: content.artistId }).then((response) => {
        if (response && response.status) {
        }
      });
    } else {
      setShowSignIn(true);
      setLoginText("Please authenticate to follow an artist.");
    }
  };
  const showMoreMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  return (
    <>
      <Link
        to={
          premium
            ? `/player/browse/artist/${content?._id}/premium`
            : book
            ? `/player/browse/artist/${content?._id}`
            : `/player/browse/artist/${content?._id}`
        }
      >
        <div
          className="hover:bg-gray-200 bg-midnight-light flex-shrink-0 w-36 md:w-44 group ease-in-out duration-300 p-3 cursor-pointer rounded-md group"
          ref={ref}
        >
          <div className="w-full h-full flex-shrink-0 flex flex-col items-center justify-center">
            <div className="w-36 h-32 md:h-36 relative shadow-xl rounded-full">
              <img
                src={blurredArtist}
                data-src={
                  content?.image
                    ? content?.image?.includes("cloudinary") ||
                      !content?.image?.includes("prod")
                      ? defaultArtist
                      : content?.image
                    : defaultArtist
                }
                loading="lazy"
                className=" h-32 md:h-36 w-36 object-cover rounded-full lazyload"
                alt="artist"
              />
              <div className="w-full absolute backdrop-filter backdrop-blur-xl backdrop-contrast-50"></div>
            </div>
            <div className="w-full h-1/4">
              <div className="w-full text-sm text-center text-left text-white group-hover:text-black font-semibold my-1 line-clamp-1">
                {content?.names || content?.email?.split("@")[0]}
              </div>
              <div className="w-full relative flex gap-x-2 items-center text-white group-hover:text-black justify-center">
                <div>
                  <RiUserFollowFill size={18} />
                </div>
                <div className="text-xs">{followersCount}</div>
                <div className="absolute right-0 hidden group-hover:block ease-in-out duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    />
                  </svg>
                </div>
                <div
                  className={`${
                    (showMenu && "flex") || "hidden"
                  } absolute right-4 -top-24 z-100 ease-in-out duration-300 flex-col w-32 gap-y-2 px-0.5 py-0.5 bg-white text-xs`}
                >
                  <div
                    className="px-4 py-2 hover:bg-gray-300"
                    onClick={userFollowArtist}
                  >
                    {!userFollowing && "Follow"}
                    {userFollowing && "unFollow"}
                  </div>
                  <Link to={`/player/browse/artist/${content?._id}`}>
                    <div className="px-4 py-2 hover:bg-gray-300">
                      View artist
                    </div>
                  </Link>
                  <div className="px-4 py-2 hover:bg-gray-300">
                    Add to playlist
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!user && showSignUp && (
          <Suspense fallback={<LoadingModal />}>
            <SignUp
              setShowSignUp={setShowSignUp}
              setShowSignIn={setShowSignIn}
              userType={"basic"}
            />
          </Suspense>
        )}
        {!user && showSignIn && (
          <Suspense fallback={<LoadingModal />}>
            <Login
              setShowSignIn={setShowSignIn}
              setShowSignUp={setShowSignUp}
              loginText={loginText}
            />
          </Suspense>
        )}
      </Link>
    </>
  );
};

export const ArtistCardLoading = () => {
  return (
    <div className="flex flex-col items-center border h-48 p-3 rounded-md gap-y-1.5">
      <div className="w-32 h-full bg-gray-300 animate-pulse rounded-full"></div>
      <div className="w-3/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      <div className="w-1/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
    </div>
  );
};
function UseOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
