import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import Login from "../../../../../components/auth/login";
import SignUp from "../../../../../components/auth/signup";
import { userStore } from "../../../../../stores/index";
import { logoutUser } from "../../../../../api/auth";
import defaultUser from "../../../../../assets/defaultArtist.jpg";
import EventModal from "../../../../../components/modals/eventModal";
import logo from "../../../../../assets/logo.jpeg";

function Navbar() {
  let [open, setOpen] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const user = userStore((state) => state.user);
  const storeUser = userStore((state) => state.storeUser);
  const location = useLocation();
  const userLogout = () => {
    logoutUser().then((response) => {
      if (response && response.status === 200) {
        storeUser(null);
        window.location.href = "/player";
      }
    });
  };
  return (
    <>
      <div className="bg-gradient-to-r from-dark-yellow to-light-yellow w-11/12 mx-auto rounded-2xl h-16 px-4 md:flex md:items-center justify-between">
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute cursor-pointer md:hidden block pt-3 z-10"
        >
          {open ? <AiOutlineClose /> : <BiMenu />}
        </div>
        <div className="text-3xl text-center md:pl-8 pl-14 absolute">
          <Link to="/player" className="font-semibold no-underline text-black">
            <img src={logo} alt="" className="  rounded-lg h-12 object-cover" />
          </Link>
        </div>

        <ul
          className={`md:flex md:items-center md:pl-80 pt-2 md:pb-0 pb-12 absolute md:static 
                       w-11/12 rounded-2xl mt-2 right-4 md:p1-0 p1-9 transition-all duration-500 
                        ease-in ${open ? "top-20" : "top-[-490px]"}`}
        >
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7 text-black">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              About
            </NavLink>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7 text-black">
            <div
              onClick={() => setEventModal(true)}
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              Events
            </div>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              Tickets
            </NavLink>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              Livestream
            </NavLink>
          </li>

          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/player/merchandise"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              Merchandise
            </NavLink>
          </li>
          <div className="ml-8  ">{/* <Search /> */}</div>
        </ul>

        <div className="flex items-center justify-end pt-4 md:pt-2">
          {!user && (
            <div
              className="no-underline text-black font-semibold mb-3 md:mr-2 cursor-pointer "
              onClick={() => setShowSignIn(true)}
            >
              SignIn/
            </div>
          )}
          {user && (
            <Link to={user.role == "artist" ? "/dashboard/artist" : "/player"}>
              <div className=" no-underline text-black font-semibold mb-3 mr-4 cursor-pointer">
                {(user && user.username) ||
                  (user && user.email && user.email.split("@")[0])}
              </div>
            </Link>
          )}
          {!user && (
            <div
              className="no-underline text-black font-semibold mb-3 mr-4 cursor-pointer"
              onClick={() => setShowSignUp(true)}
            >
              SignUp
            </div>
          )}
          {!user && <BsFillPersonCheckFill className="text-2xl mb-3" />}
          {user && (
            <div className=" group">
              <img
                data-src={
                  user?.image
                    ? (user.image.includes("cloudinary") && defaultUser) ||
                      user.image
                    : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                }
                className="w-10 h-8 rounded-full object-cover mb-3 lazyload"
                alt="User profile"
              />
              <ul
                className={`group-hover:block absolute hidden  w-64 -ml-52 bg-midnight border border-gray-100  -mt-2 z-100  shadow-md`}
              >
                <li className="flex items-center space-x-4 border-b border-gray-300 py-4 pl-2">
                  <div className="">
                    <img
                      data-src={
                        user?.image
                          ? (user.image.includes("cloudinary") &&
                              defaultUser) ||
                            user.image
                          : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                      }
                      className="w-8 h-8 rounded-full object-cover lazyload"
                      alt="User profile"
                    />
                  </div>
                  <div className={`capitalize text-sm  text-white`}>
                    {(user && user.username) ||
                      (user && user.email && user.email.split("@")[0])}
                  </div>
                </li>

                <li
                  className="flex items-center space-x-4 py-4 pl-2 text-white  cursor-pointer"
                  onClick={userLogout}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <div className={`capitalize text-sm  `}>Logout</div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {eventModal && <EventModal closeHandler={() => setEventModal(false)} />}
      {showSignIn && (
        <Login setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} />
      )}
      {showSignUp && (
        <SignUp
          setShowSignIn={setShowSignIn}
          setShowSignUp={setShowSignUp}
          userType={location.pathname === "/player" ? "artist" : "basic"}
        />
      )}
    </>
  );
}

export default Navbar;
