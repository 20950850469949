import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai";
import { Spinner } from "../utils/spinningLoaderBlack";
import { registerEvent } from "../../api/auth";

function EventModal({ closeHandler }) {
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState();

  const onSubmit = async () => {
    if (names == "" || email == "" || phone == "") {
      return;
    }
    try {
      setLoading(true);
      const res = await registerEvent({
        names: names,
        phone: phone,
        email: email,
        eventName: "Sample Event",
      });
      closeHandler();
    } catch (error) {
      closeHandler();
    }
    setLoading(false);
  };
  return (
    <>
      <div
        className="fixed w-full inset-0 z-75 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
        style={{ background: "rgba(0,0,0,.6)" }}
      >
        <div className="shadow-xl modal-container pb-8 bg-white w-full md:w-3/5 lg:w-3/5 xl:w-1/3 mx-auto rounded-t-lg md:rounded-lg z-100 overflow-y-auto max-h-full">
          <div className="modal-content text-left">
            <div className="flex w-full flex-row-reverse">
              <div
                className=" mt-1 mr-4 p-2 hover:bg-gray-200 hover:text-black rounded-lg cursor-pointer"
                onClick={closeHandler}
              >
                <AiOutlineClose className="" />
              </div>
            </div>
            <div className="px-6 pb-6">
              <div className=" text-black text-lg font-semibold text-center ">
                Magic Event Registration
              </div>
              <div className="py-1">
                <label
                  htmlFor="name"
                  className="text-xs text-black
         "
                >
                  Full Names
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={(e) => setNames(e.target.value)}
                  className="w-full rounded-md text-black border placeholder-white text-sm  focus:outline-none focus:ring-0 focus:border-bloow-blue bg-transparent"
                  placeholder="Enter Full Name"
                  required
                />
              </div>
              <div className="py-1">
                <label
                  htmlFor="email"
                  className="text-xs text-black
         "
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full rounded-md text-black bg-transparent placeholder-white text-sm border focus:outline-none focus:ring-0 focus:border-bloow-blue "
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div className="py-1">
                <label
                  htmlFor="phone"
                  className="text-xs text-black
         "
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full rounded-md text-black bg-transparent placeholder-white text-sm border focus:outline-none focus:ring-0 focus:border-bloow-blue "
                  placeholder="Enter Phone Number"
                  required
                />
              </div>

              <button
                className=" bg-gradient-to-r from-dark-yellow to-light-yellow w-full flex justify-center items-center rounded-md px-4 py-1"
                onClick={onSubmit}
              >
                <div className=" text-center">
                  {loading ? <Spinner /> : "Submit"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventModal;
