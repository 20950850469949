import React, { useState, useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { GenreCard, LoadingGenre } from "./genreCard";
import { Link } from "react-router-dom";
import { getAllGenres } from "../../../../../api/auth";
function GenreSection() {
  const [genres, setGenres] = useState();
  const [loadingGenres, setLoadingGenres] = useState(true);
  useEffect(() => {
    setLoadingGenres(true);
    getAllGenres().then((response) => {
      if (response && response.status === 200) {
        setGenres(response.data.contentCategories);
        setLoadingGenres(false);
      }
    });
  }, []);
  return (
    <div className="text-white w-full px-3 pb-4">
      <div className=" flex justify-between items-center">
        <div className="">
          <div className=" text-white font-black">Browse</div>
          <div className=" text-xs text-gray-300">
            Explore by genre and mood
          </div>
        </div>
        <Link to={"/player/browse"}>
          <div className=" flex items-center ">
            <div className=" text-gray-100 text-sm pr-1">View All</div>
            <div className="">
              <AiOutlineRight className=" text-gray-100" />
            </div>
          </div>
        </Link>
      </div>
      <div className="  flex h-28  gap-x-2 overflow-x-scroll flex-shrink-0 no-scrollbar">
        {loadingGenres && [1, 3].map(() => <LoadingGenre />)}
        {!loadingGenres &&
          genres.map((content) => <GenreCard content={content} />)}
      </div>
    </div>
  );
}

export default GenreSection;
