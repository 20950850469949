import { useRef, useEffect, useState, Suspense, lazy } from "react";
import { getClientInfoFromIp } from "../../api/ip";
import { createUsersRecentPlays } from "../../api/streams";
import { useLocation } from "react-router-dom";
import loadSound from "../../assets/load-sound.mp3";
import {
  BiPlay,
  BiPause,
  BiSkipPrevious,
  BiSkipNext,
  BiShuffle,
  BiRepost,
  BiListUl,
  BiVolumeFull,
} from "react-icons/bi";
import { MdHowToVote } from "react-icons/md";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { usePlaylist } from "../../stores";
import { userStore } from "../../stores/index";
import { likeContent } from "../../api/auth";

import defaultSong from "../../assets/defaultSong.webp";

const Login = lazy(() => import("../auth/login"));
const SignUp = lazy(() => import("../auth/signup"));
const VotingModal = lazy(() => import("../modals/votingModal"));

const mode = {
  light: {
    text: "text-bloow-black",
    background: "bg-white",
    navLink: "text-black",
    navText: "text-bloow-blue",
  },
  dark: {
    text: "text-white",
    background: "bg-bloow-blue",
    navLink: "text-black",
    navText: "text-white",
  },
};
export const WavePlayer = ({ url, togglePlay }) => {
  return <div></div>;
};

export const MusicPlayer = () => {
  const playlist = usePlaylist((state) => state.playlist);
  const changePlaylist = usePlaylist((state) => state.changePlaylist);
  const isPlaying = usePlaylist((state) => state.isPlaying);
  const setIsPlaying = usePlaylist((state) => state.setIsPlaying);
  const currentlyPlaying = usePlaylist((state) => state.currentlyPlaying);
  const setCurrentlyPlaying = usePlaylist((state) => state.setCurrentlyPlaying);

  const [percentage, setPercentage] = useState(0);
  const [ipAddress, setIpAddress] = useState();
  const [content, setContent] = useState(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0.9);
  const [volumePercentage, setVolumePercentage] = useState(90);
  const [loop, setLoop] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const [stream, setStream] = useState(false);
  const [liked, setLiked] = useState();
  const [isBlocked, setIsBlocked] = useState(false);
  const [loginText, setLoginText] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const user = userStore((state) => state.user);
  const audioRef = useRef();
  const volumeRangeRef = useRef();
  const playRef = useRef();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      setLiked(
        user?.likedContent?.find((like) => {
          return like?.contentId == content?._id;
        })
      );
    }
  }, [user]);
  useEffect(() => {
    if (isBlocked) {
      const audio = audioRef.current;

      audio.pause();
      return;
    }
  }, [isBlocked]);
  // useEffect(() => {

  //   return () => {
  //     setIsPlaying(false);
  //   };
  // }, []);
  useEffect(async () => {
    const response = await getClientInfoFromIp();
    setIpAddress(response?.data?.ip);
  }, []);
  useEffect(async () => {
    if (stream) {
      const response = await createUsersRecentPlays({
        contentId: playlist[currentlyPlaying]._id,
        ipAddress: ipAddress,
      });
    }
  }, [stream]);

  useEffect(() => {
    const storedPlaylist = window.localStorage.getItem("playlist");
    if (storedPlaylist) {
      changePlaylist(JSON.parse(storedPlaylist));
    }
  }, []);

  useEffect(() => {
    if (playlist.length > 0) {
      setContent(playlist[0]);
      window.localStorage.setItem("playlist", JSON.stringify(playlist));
    }
  }, [playlist]);

  useEffect(() => {
    if (currentTime === duration && playlist.length > 0 && isPlaying) {
      setTimeout(() => {
        playNext();
      }, 1500);
    }
  }, [currentTime, duration]);

  useEffect(() => {
    if (content && isPlaying) {
      const audio = audioRef.current;
      audio.load();
      audio.volume = volume;
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then((res) => {
            setIsPlaying(true);
          })
          .catch((err) => {
            setIsPlaying(false);
          });
      }
    }
  }, [content]);

  useEffect(() => {
    const audio = audioRef.current;

    if ((!isPlaying && content) || isBlocked) {
      audio.pause();
      return;
    }
    if (isPlaying && content && !isBlocked) {
      audio.play();
      return;
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (playlist.length > 0 && currentlyPlaying !== playlist?.length) {
      setContent(playlist[currentlyPlaying]);
    }
  }, [currentlyPlaying]);

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const onChangeVolume = (e) => {
    setVolume(parseFloat(e.target.value));
    setVolumePercentage(e.target.value * 100);
  };

  // const play = () => {
  //     const audio = audioRef?.current;
  //     audio.volume = volume;
  //     if (!isPlaying) {
  //         setIsPlaying(true);
  //         audio.play();
  //     }
  //     if (isPlaying) {
  //         setIsPlaying(false);
  //         audio.pause();
  //     }
  // };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(percent);
    setCurrentTime(time.toFixed(2));
    if (currentTime > 10 && !user) {
      setIsPlaying(false);
      setLoginText("Please sign in to continue streaming.");
      setIsBlocked(true);
      setShowSignIn(true);
    }
    if (currentTime > 10 && !stream) {
      setStream(true);
    }
    if (currentTime < 10 && stream) {
      setStream(false);
    }
  };

  const isPlayingLastSong = () => {
    return playlist?.length - 1 === currentlyPlaying;
  };

  const isPlayingFirstSong = () => {
    return currentlyPlaying === 0;
  };

  const playNext = () => {
    if (isPlayingLastSong() && !loop && !shuffle) {
      setCurrentlyPlaying(0);
      setIsPlaying(true);
    }
    if (!isPlayingLastSong() && !loop && !shuffle) {
      setCurrentlyPlaying(currentlyPlaying + 1);
      setIsPlaying(true);
    }
    if (shuffle) {
      const allSongs = playlist.length;
      let nextSong = Math.floor(Math.random() * allSongs);
      while (nextSong === currentlyPlaying) {
        nextSong = Math.floor(Math.random() * allSongs);
      }
      setCurrentlyPlaying(nextSong);
      setIsPlaying(true);
    }
  };

  const playPrevious = () => {
    if (currentTime > 10) {
      const audio = audioRef.current;
      audio.currentTime = 0;
    } else if (playlist?.length > 1 && !isPlayingFirstSong()) {
      setCurrentlyPlaying(currentlyPlaying - 1);
    } else if (playlist?.length > 1 && isPlayingFirstSong()) {
      setCurrentlyPlaying(playlist.length - 1);
    }
  };
  const likingContent = () => {
    if (user) {
      likeContent({ contentId: content._id }).then((response) => {});
      if (liked) {
        setLiked(!liked);
      } else {
        setLiked(!liked);
      }
    } else {
      setShowSignIn(true);
      setLoginText("Please authenticate to like a content.");
    }
  };
  return (
    <>
      <div
        className={`${
          playlist?.length > 0 ? "block" : "hidden"
        } w-full    flex justify-between items-center `}
      >
        <div className="w-full md:w-1/4 flex px-2 gap-x-2">
          <div className="w-12 h-12">
            <img
              src={
                content?.thumbnailPath
                  ? content?.thumbnailPath.includes("cloudinary") ||
                    !content?.thumbnailPath.includes("prod") ||
                    !content?.thumbnailPath.includes("prod")
                    ? defaultSong
                    : content?.thumbnailPath
                  : defaultSong
              }
              className="rounded-md w-full h-full object-cover"
            />
          </div>
          <div className={`flex flex-col justify-center    ${mode.light.text}`}>
            <div className="text-sm cursor-pointer hover:underline line-clamp-1">
              {content?.contentName.replace(".mp3", "")}
            </div>
            <div className="text-xs cursor-pointer hover:underline text-gray-400 line-clamp-1">
              {content?.userId?.names || content?.userId?.email?.split("@")[0]}
            </div>
          </div>
        </div>
        <div className="flex flex-grow flex-col items-center justify-center">
          <ControlPlanel
            setIsPlaying={setIsPlaying}
            isPlaying={isPlaying}
            playRef={playRef}
            playNext={playNext}
            playPrevious={playPrevious}
            loop={loop}
            setLoop={setLoop}
            shuffle={shuffle}
            setShuffle={setShuffle}
            isBlocked={isBlocked}
          />
          <Slider
            percentage={percentage}
            onChange={onChange}
            duration={duration}
            currentTime={currentTime}
          />
          <audio
            ref={audioRef}
            onTimeUpdate={getCurrDuration}
            onLoadedData={(e) => {
              setDuration(e.currentTarget.duration.toFixed(2));
            }}
            // src={loadSound}
            src={(content?.filePath && content?.filePath) || loadSound}
          ></audio>
        </div>
        <div className="w-1/4 hidden md:flex justify-end gap-x-2 items-center px-4">
          <div
            className="hover:bg-gray-200 p-2 rounded-full transition-all cursor-pointer"
            onClick={likingContent}
          >
            {liked && <AiFillHeart size={18} className=" text-red-500 " />}
            {!liked && <AiOutlineHeart size={18} className=" text-red-500 " />}
          </div>
          <div
            className="hover:bg-gray-200 p-2 rounded-full transition-all cursor-pointer"
            onClick={() => setShowVotingModal(true)}
          >
            <MdHowToVote size={18} className="" />
          </div>

          <div className="hover:bg-gray-200 p-2 rounded-full transition-all cursor-pointer">
            <BiListUl size={20} className={`${mode.light.text}`} />
          </div>
          <div>
            <BiVolumeFull size={18} className={`${mode.light.text}`} />
          </div>
          <div className="w-32 relative flex items-center">
            <div className="absolute w-full h-1 bg-gray-200"></div>
            <div
              className="absolute h-1 bg-gradient-to-r from-green to-bloow-blue rounded-full"
              style={{ width: `${volumePercentage}%` }}
            ></div>
            <div
              className="absolute w-3 h-3 rounded-full bg-gradient-to-r from-green to-bloow-blue transition-all"
              style={{ left: `${volumePercentage}%` }}
            ></div>
            <input
              type="range"
              value={volume}
              ref={volumeRangeRef}
              step="0.01"
              min="0"
              max="1"
              className="range"
              onChange={onChangeVolume}
            />
          </div>
        </div>
      </div>
      {showVotingModal && (
        <Suspense fallback={<div>loading ...</div>}>
          <VotingModal
            closeHandler={() => setShowVotingModal(false)}
            contentId={content._id}
          />
        </Suspense>
      )}
      {!user && showSignIn && (
        <Suspense fallback={<div>loading ...</div>}>
          <Login
            setShowSignIn={setShowSignIn}
            setShowSignUp={setShowSignUp}
            loginText={loginText}
          />
        </Suspense>
      )}

      {!user && showSignUp && (
        <Suspense fallback={<div>loading ...</div>}>
          <SignUp
            setShowSignUp={setShowSignUp}
            setShowSignIn={setShowSignIn}
            userType={"basic"}
          />
        </Suspense>
      )}
    </>
  );
};

function Slider({ percentage = 0, onChange, duration, currentTime }) {
  const [position, setPosition] = useState(0);

  const rangeRef = useRef();

  useEffect(() => {}, [percentage]);

  function secondsToHms(seconds) {
    if (!seconds) return "00:00";

    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }

    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}h ${min}m ${sec}s`;
    } else if (min == 0) {
      return `00:${sec}`;
    } else {
      return `${min}:${sec}`;
    }
  }

  return (
    <div className="w-full hidden md:flex gap-x-2 items-center">
      <div className={`${mode.light.text} text-xs`}>
        {secondsToHms(currentTime)}
      </div>
      <div className=" relative w-full h-1 bg-gray-200 rounded-full">
        <div
          className="absolute bg-gradient-to-r from-green to-bloow-blue w-1/2 h-full rounded-full cursor-pointer transition-all"
          style={{ width: `${percentage}%` }}
        ></div>
        <div
          className="absolute w-3 h-3 rounded-full bg-gradient-to-r from-green to-bloow-blue -top-1 transition-all"
          style={{ left: `${percentage}%` }}
        ></div>
        <div className="absolute w-full -top-1.5">
          <input
            type="range"
            value={position}
            ref={rangeRef}
            step="0.01"
            className="range"
            onChange={onChange}
          />
        </div>
      </div>
      <div className={`${mode.light.text} text-xs`}>
        {secondsToHms(duration)}
      </div>
    </div>
  );
}

const ControlPlanel = ({
  setIsPlaying,
  isPlaying,
  playNext,
  playPrevious,
  loop,
  setLoop,
  shuffle,
  setShuffle,
  playRef,
  isBlocked,
}) => {
  return (
    <div className="flex gap-x-4 items-center">
      <div
        className={`${
          (shuffle && "bg-gray-300") || "hover:bg-gray-200"
        } rounded-full p-1.5 cursor-pointer`}
        onClick={() => {
          setShuffle((prevState) => !prevState);
        }}
      >
        <BiShuffle
          size={20}
          className={`${mode.light.text} cursor-pointer hover:bg-gray-200 rounded-full`}
        />
      </div>
      <BiSkipPrevious
        size={35}
        className={`${mode.light.text} cursor-pointer hover:bg-gray-200 rounded-full`}
        onClick={playPrevious}
      />
      <Button
        play={setIsPlaying}
        isPlaying={isPlaying}
        playRef={playRef}
        isBlocked={isBlocked}
      />
      <BiSkipNext
        size={35}
        className={`${mode.light.text} cursor-pointer hover:bg-gray-200 rounded-full`}
        onClick={playNext}
      />
      <div
        className={`${
          (loop && "bg-gray-300") || "hover:bg-gray-200"
        } rounded-full p-1.5 cursor-pointer hidden md:block`}
        onClick={() => {
          setLoop((prevState) => !prevState);
        }}
      >
        <BiRepost size={23} className={`${mode.light.text}`} />
      </div>
    </div>
  );
};

function Button({ isPlaying, play, playRef, isBlocked }) {
  useEffect(() => {
    document.addEventListener("keyup", enterPlayPause);
  }, []);

  const enterPlayPause = (e) => {
    if (e.keyCode === 32) {
      play((prevState) => !prevState);
    }
  };

  return (
    <div className="">
      {(!isPlaying && (
        <BiPlay
          size={35}
          className={`${mode.light.text} cursor-pointer `}
          onClickCapture={isBlocked ? () => {} : () => play(!isPlaying)}
          onKeyUp={enterPlayPause}
          ref={playRef}
        />
      )) || (
        <BiPause
          size={35}
          className={`${mode.light.text} cursor-pointer`}
          onClickCapture={isBlocked ? () => {} : () => play(!isPlaying)}
          onKeyUp={enterPlayPause}
        />
      )}
    </div>
  );
}
