import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserView, MobileView } from "react-device-detect";
// import { Offline, Online } from "react-detect-offline";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import DestopApp from "./desktop/App";
import MobileApp from "./mobile/App";
import "./index.css";

Sentry.init({
  dsn: "https://906c6d1259ee405b814c699c9ef036b1@o1135347.ingest.sentry.io/6184178",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserView>
      <DestopApp />
    </BrowserView>
    <MobileView>
      <MobileApp />
    </MobileView>
    {/* <Offline>
        <div className="w-full h-screen flex justify-center items-center">
            <div className="">
              Internet is not available
            </div>
        </div>
      </Offline> */}
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
