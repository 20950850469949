import { useEffect, useState, Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import history from "../default";
import { refreshToken } from "../api/auth";
import { userStore } from "../../src/stores";
import { Spinner } from "../components/utils/spinningLoaderBlack";
import ".././modal.css";
import LandingThree from "./pages/landing/landingThree/unauthenticatedHomepage";
import AuthenticatedHome from "./pages/landing/landingThree/authenticatedHomepage";
import Player from "./pages/player/dashboard";

const Business = lazy(() => import("./pages/landing/landingThree/business"));
const Fans = lazy(() => import("./pages/landing/landingThree/fans"));
const Artists = lazy(() => import("./pages/landing/landingThree/artists"));
const Agency = lazy(() => import("./pages/landing/landingThree/agency"));

const About = lazy(() => import("./pages/landing/landingThree/about"));
const Ticketing = lazy(() => import("./pages/landing/landingThree/ticketing"));
const LandingArtist = lazy(() => import("./pages/landing/landingArtist"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
//const PublicArtist = lazy(() => (import('./pages/dashboard/publicArtist')));
const PublicEvent = lazy(() => import("./pages/events/publicEvent"));
const GoToEvent = lazy(() => import("./pages/events/goToEvent"));
const ArtistGoToEvent = lazy(() => import("./pages/events/artistEvent"));
const ResetPasswordOne = lazy(() => import("../components/resetPasswordOne"));
const ResetPasswordTwo = lazy(() => import("../components/resetPasswordTwo"));
const GenreOverview = lazy(() =>
  import("./pages/player/components/genre/genreOverview")
);
const GenrePlaylist = lazy(() =>
  import("./pages/player/components/genre/genrePlaylist")
);
const PublicArtist = lazy(() =>
  import("./pages/player/components/artist/publicArtist")
);

const DesktopApp = () => {
  const user = userStore((state) => state.user);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const storeUser = userStore((state) => state.storeUser);

  useEffect(() => {
    const fetchNewToken = async () => {
      try {
        setIsLoadingUser(true);
        const res = await refreshToken();

        setIsLoadingUser(false);
        if (res && res.status === 200) {
          storeUser(res.data.user);
        }
      } catch (error) {}
    };

    if (!user) {
      fetchNewToken();
    }
  }, [user, storeUser]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <LandingThree isLoadingUser={isLoadingUser} />
        </Route>
        <Route exact path="/home">
          <AuthenticatedHome isLoadingUser={isLoadingUser} />
        </Route>

        <Route path="/dashboard/artist">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Dashboard isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route exact path="/business">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Business />
          </Suspense>
        </Route>
        <Route exact path="/artists">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Artists />
          </Suspense>
        </Route>
        <Route exact path="/agency">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Agency />
          </Suspense>
        </Route>

        <Route exact path="/fans">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Fans />
          </Suspense>
        </Route>

        <Route exact path="/about">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <About isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route exact path="/ticketing/:id">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Ticketing isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route exact path="/resetPassword">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <ResetPasswordOne />
          </Suspense>
        </Route>
        <Route exact path="/resetPassword/:resetpassword">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <ResetPasswordTwo />
          </Suspense>
        </Route>
        <Route exact path="/artist">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <LandingArtist isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path={"/player" || "/player"}>
          <Player />
        </Route>

        <Route exact path="/nft_marketplace">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Dashboard isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route exact path="/artist/:artistId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <PublicArtist isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/artist/:artistId/event/:eventId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <PublicEvent isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/event/:eventId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <GoToEvent isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/artist/event/:eventId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <ArtistGoToEvent isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route exact path="/artist/:artistId/content/:contentId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <PublicArtist isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/play/browse/genre/:genre/playlist">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <GenrePlaylist isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/play/browse/genre/:genre">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <GenreOverview isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>
        <Route path="/play/browse/artist/:artistId">
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <PublicArtist isLoadingUser={isLoadingUser} />
          </Suspense>
        </Route>

        <Route path={"/player"}>
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spinner />
              </div>
            }
          >
            <Dashboard />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  );
};

export default DesktopApp;
