import { useState, useEffect } from "react";
import { getRecommendedArtists } from "../../../../../api/streams";
import { userStore } from "../../../../../stores";

import { ArtistCard, ArtistCardLoading } from "../../../cards/artistCards";

export const RecommendedStreams = () => {
  const user = userStore((state) => state.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [finalPage, setFinalPage] = useState(null);
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRecommendedArtists(page, limit).then((response) => {
      setLoading(false);
      if (response && response.status === 200) {
        setArtists(response.data.artists);
      }
      if (response && response.status === 204) {
        setFinalPage(true);
      }
    });
  }, [user, page, limit]);

  const previousPage = () => {
    if (finalPage) {
      setFinalPage(false);
      setPage((prevState) => prevState - 2);
      return;
    }
    if (page !== 0) {
      setPage((prevState) => prevState - 1);
      return;
    }
  };
  const nextPage = () => {
    if (!finalPage) {
      setPage((prevState) => prevState + 1);
    }
  };
  return (
    <>
      {(loading && <RecommendedStreamsLoading />) || (
        <div className="w-full flex flex-col pb-16">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">Recommended Artists</div>
            <div className="flex items-center">
              <div
                onClick={page === 1 ? () => {} : previousPage}
                className="cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    page === 1 ? "text-gray-600" : "text-white"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div onClick={nextPage} className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    (finalPage && "text-gray-600") || "text-white"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
            {artists.length > 0 &&
              artists?.map((content, key) => {
                return (
                  <ArtistCard
                    key={key}
                    content={content}
                    onClickHandler={() => {}}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export const RecommendedStreamsLoading = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center justify-between">
        <div className="w-32 h-7 bg-gray-300 animate-pulse rounded-md"></div>
        <div className="w-16 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div className="w-full h-52 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
        {Array(6)
          .fill(1)
          .map((el, index) => {
            return <ArtistCardLoading />;
          })}
      </div>
    </div>
  );
};
