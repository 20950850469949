import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { clientGetBookings } from "../../../../../api/auth";
function UserBookings() {
  const [loading, setLoading] = useState();
  const [bookings, setBookings] = useState([]);
  useEffect(() => {
    const getContent = async () => {
      setLoading(true);
      const res = await clientGetBookings();
      setLoading(false);
      if (res && res.status == 200) {
        setBookings(res.data.bookings);
      }
    };
    getContent();
  }, []);
  return (
    <div>
      <div className="w-full flex items-center  pb-4 justify-between">
        <div className="  flex space-x-4">
          <Link
            to={"/player/book"}
            className=" text-white   cursor-pointer hover:text-green"
          >
            Talents
          </Link>
          <Link
            to={"/player/book"}
            className=" text-green   cursor-pointer hover:text-white"
          >
            Your Bookings
          </Link>
        </div>
        <div className="flex items-center"></div>
      </div>
      <table className="min-w-full table-fixed divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="pr-3 md:py-3.5 py-1 pl-2 text-left text-sm font-semibold text-gray-900"
            >
              Artist
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1  md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Title
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1 md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Location
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1 md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Time
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1 md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Offer
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1 md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Payment Status
            </th>
            <th
              scope="col"
              className="min-w-[12rem] md:py-3.5 py-1 md:pl-0 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Approval Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {!loading &&
            bookings?.length > 0 &&
            bookings.map((booking) => (
              <tr>
                <td className="whitespace-nowrap  md:pl-0 pl-2 pr-3 text-left py-4 text-sm text-white">
                  {booking?.artist?.names}
                </td>
                <td className="whitespace-nowrap py-4 md:pl-0 pl-2 pr-3 text-left text-sm font-medium text-white">
                  {booking?.title}
                </td>
                <td className="whitespace-nowrap py-4 md:pl-0 pl-2 pr-3 text-left text-sm font-medium text-white">
                  {booking?.location}
                </td>
                <td className="whitespace-nowrap py-4 md:pl-0 pl-2 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.time}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  <span className=" text-green">{booking?.currency}</span>{" "}
                  {booking?.offer}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.paymentStatus}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.status}
                </td>
              </tr>
            ))}
          {loading && <BookingsListLoading />}
        </tbody>
      </table>
    </div>
  );
}
export const BookingsListLoading = () => {
  return Array(5)
    .fill(1)
    .map((el, index) => {
      return (
        <tr>
          <td className="whitespace-nowrap pl-2 pr-3 text-left py-4 text-sm text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
        </tr>
      );
    });
};
export default UserBookings;
