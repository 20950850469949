import React from "react";
// IMPORT COMPONENTS
import Navbar from "./components/Navbar";
import ImageSlider from "./components/ImageSlider";
import VideoSlider from "./components/VideoSlider";
import Leaderboard from "./components/Leaderboard";
import EventsSlider from "./components/EventsSlider";
import ArtistSlider from "./components/ArtistSlider";
import Awards from "./components/Awards";
import TvRadio from "./components/TvRadio";
import Contact from "./components/Contact";
import SocialMedia from "./components/SocialMedia";
import Footer from "./components/Footer";
import LandingVideoOne from "../../../../assets/wyreVideo.png";
import { IoIosArrowBack } from "react-icons/io5";
// OWL CAROUSEL
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  {
    title: "Image Two",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2F540px%20%20x540px.jpg?alt=media&token=ccba3c2a-471c-4cb8-ac35-6d48ebec8488",
  },
  {
    title: "Image One",
    image:
    "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2F540px%20%20x540px.jpg?alt=media&token=ccba3c2a-471c-4cb8-ac35-6d48ebec8488",
  },
  {
    title: "Image Three",
    image:
    "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2F540px%20%20x540px.jpg?alt=media&token=ccba3c2a-471c-4cb8-ac35-6d48ebec8488",
  },
];

const songs = [
  {
    title: "Tee",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FTee.jpg?alt=media&token=536b9696-b644-460b-a696-b84d593de7b3",
    link: "/ticketing/66046a360397e325103bde70",
  },

  {
    title: "Benjamin",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBenjamin.jpg?alt=media&token=ed05f963-0e65-4d0b-aaff-6dd5ecc3eb94",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Badoo",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBadoo.jpg?alt=media&token=348aca95-bbee-4f16-b6a3-aa7d987077b4",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Shalate",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FSHALATE.jpg?alt=media&token=9c5d0404-88fb-47a4-a346-95f4c6343e1e",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "XXC",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FXXC.jpg?alt=media&token=471633af-0a9f-4c9e-b1bd-25068f4a6bd3",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Belo",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBelo.jpg?alt=media&token=ddd7d7a1-6dc9-484f-b89d-159ee3afd2b6",
    link: "/ticketing/66046a360397e325103bde70",
  },

  {
    title: "Vegas",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FVegas.jpg?alt=media&token=bfb08582-140e-4122-a52b-b660da3645d8",
    link: "/ticketing/66046a360397e325103bde70",
  },
];

const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  navText: [
    '<div class="text-blue text-4xl -ml-14"><</div>',
    '<div class="text-blue text-4xl -ml-5">></div>',
  ],
};

function AuthenticatedHome() {
  return (
    <div className="bg-midnight pt-6 no-scroll">
      <Navbar />
      <ImageSlider images={images} />

      <section className="my-10">
        <h2 className="text-center text-green  text-4xl">Shoot Out!</h2>
      </section>

      <VideoSlider image={LandingVideoOne} />
      <Leaderboard />

      <ArtistSlider title="Other Performing Artists" items={songs} />

      {/* <section className="key--features--section p-10 w-screen overflow-x-hidden">
        <p className="text-white text-2xl font-mono text-center">
          Key Features on Bloow
        </p>
        <div className="w-3/4 mx-auto">
          <OwlCarousel {...options}>
            <div className="item w-full">
              <div className=" w-full ">
                <ul className="text-white font-thin  w-full ">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>

            <div className="item w-full">
              <div className="">
                <ul className="text-white font-thin w-full">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>

            <div className="item w-11/12 mx-auto">
              <div className="">
                <ul className="text-white font-thin w-full">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section> */}

      <EventsSlider />

      <Awards />

      <TvRadio image={LandingVideoOne} />

      <Contact />

      <SocialMedia />

      <Footer />
    </div>
  );
}

export default AuthenticatedHome;
