import React from "react";
// IMPORT COMPONENTS
import Navbar from "./components/Navbar";
import ImageSlider from "./components/ImageSlider";
import VideoSlider from "./components/VideoSlider";
import Leaderboard from "./components/Leaderboard";
import EventsSlider from "./components/EventsSlider";
import ArtistSlider from "./components/ArtistSlider";
import Awards from "./components/Awards";
import TvRadio from "./components/TvRadio";
import Contact from "./components/Contact";
import SocialMedia from "./components/SocialMedia";
import Footer from "./components/Footer";
import LandingVideoOne from "../../../../assets/wyreVideo.png";
import Khaligraph from "../../../../assets/khaligraph.png";
// OWL CAROUSEL
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  {
    title: "Events",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2FWhatsApp%20Image%202024-02-26%20at%2011.50.09%20AM%402x.png?alt=media&token=fb42fb40-bba1-44c7-a0c8-40beb345fd2f",
  },
  {
    title: "Khaligraph",
    image:
    "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2FWhatsApp%20Image%202024-02-26%20at%2011.50.09%20AM%402x.png?alt=media&token=fb42fb40-bba1-44c7-a0c8-40beb345fd2f",
  },
  {
    title: "Jammin",
    image:
    "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2FWhatsApp%20Image%202024-02-26%20at%2011.50.09%20AM%402x.png?alt=media&token=fb42fb40-bba1-44c7-a0c8-40beb345fd2f",
  },
];

const songs = [
  {
    title: "Tee",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FTee.jpg?alt=media&token=536b9696-b644-460b-a696-b84d593de7b3",
    link: "/ticketing/66046a360397e325103bde70",
  },

  {
    title: "Benjamin",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBenjamin.jpg?alt=media&token=ed05f963-0e65-4d0b-aaff-6dd5ecc3eb94",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Badoo",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBadoo.jpg?alt=media&token=348aca95-bbee-4f16-b6a3-aa7d987077b4",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Shalate",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FSHALATE.jpg?alt=media&token=9c5d0404-88fb-47a4-a346-95f4c6343e1e",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "XXC",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FXXC.jpg?alt=media&token=471633af-0a9f-4c9e-b1bd-25068f4a6bd3",
    link: "/ticketing/66046a360397e325103bde70",
  },
  {
    title: "Belo",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FBelo.jpg?alt=media&token=ddd7d7a1-6dc9-484f-b89d-159ee3afd2b6",
    link: "/ticketing/66046a360397e325103bde70",
  },

  {
    title: "Vegas",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fwhitelabel%2Fimages%2FVegas.jpg?alt=media&token=bfb08582-140e-4122-a52b-b660da3645d8",
    link: "/ticketing/66046a360397e325103bde70",
  },
];

const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  navText: [
    '<i class="bi bi-chevron-left left text-white"></i>',
    '<i class="bi bi-chevron-right text-white"></i>',
  ],
};

function AuthenticatedHome() {
  return (
    <div className="bg-midnight pt-6">
      <Navbar />
      <ImageSlider images={images} />

      <section className="my-5">
        <h2 className="text-center text-light-yellow text-4xl">Shoutout!</h2>
      </section>

      <VideoSlider image={LandingVideoOne} />
      <ArtistSlider title="Other Perfoming Artists" items={songs} />
      <Leaderboard />

      {/* 
      <section className="key--features--section p-10">
        <OwlCarousel {...options}>
          <div className="item pb-20">
            <p className="text-white text-2xl font-mono relative top-24 md:left-80 md:left-40 left-20">
              Key Features <br /> on Bloow
            </p>

            <div className="flex items-center">
              <img
              className="w-20 mt-48"
              src={navigatePrev}
              alt="navigate prev icon"
            />
              <ul className="text-white font-thin relative inset-x-2/3 top-32 inset-x-1/4 md:mx-auto">
                <li>Merchandise</li>
                <li>Voting & Awards</li>
                <li>Events & Concerts Booking</li>
                <li>Milestones</li>
                <li>Bloow Studio</li>
                <li>Bloow Label</li>
                <li>TV & Radio</li>
                <li>Store</li>
                <li>NFTs</li>
                <li>Finance</li>
                <li>Streaming</li>
                <li>Portfolio</li>
                <li>Affiliate Programs</li>
              </ul>
              <img
              className="-w-1 md:mt-40 md:block mr-4 hidden "
              src={navigateNext}
              alt="navigate next icon"
            />
            </div>
          </div>

          <div className="item">
            <p className="text-white text-2xl font-mono relative top-24 md:left-80 md:left-40 left-20">
              Key Features <br /> on Bloow
            </p>

            <div className="flex items-center">
              <img
              className="w-20 mt-48"
              src={navigatePrev}
              alt="navigate prev icon"
            />
              <ul className="text-white font-thin relative inset-x-2/3 top-32 inset-x-1/4 md:mx-auto">
                <li>Merchandise</li>
                <li>Voting & Awards</li>
                <li>Events & Concerts Booking</li>
                <li>Milestones</li>
                <li>Bloow Studio</li>
                <li>Bloow Label</li>
                <li>TV & Radio</li>
                <li>Store</li>
                <li>NFTs</li>
                <li>Finance</li>
                <li>Streaming</li>
                <li>Portfolio</li>
                <li>Affiliate Programs</li>
              </ul>
              <img
              className="-w-1 md:mt-40 md:block mr-4 hidden "
              src={navigateNext}
              alt="navigate next icon"
            />
            </div>
          </div>

          <div className="item">
            <p className="text-white text-2xl font-mono relative top-24 md:left-80 md:left-40 left-20">
              Key Features <br /> on Bloow
            </p>

            <div className="flex items-center">
              <img
              className="w-20 mt-48"
              src={navigatePrev}
              alt="navigate prev icon"
            />
              <ul className="text-white font-thin relative inset-x-2/3 top-32 inset-x-1/4 md:mx-auto">
                <li>Merchandise</li>
                <li>Voting & Awards</li>
                <li>Events & Concerts Booking</li>
                <li>Milestones</li>
                <li>Bloow Studio</li>
                <li>Bloow Label</li>
                <li>TV & Radio</li>
                <li>Store</li>
                <li>NFTs</li>
                <li>Finance</li>
                <li>Streaming</li>
                <li>Portfolio</li>
                <li>Affiliate Programs</li>
              </ul>
              <img
              className="-w-1 md:mt-40 md:block mr-4 hidden "
              src={navigateNext}
              alt="navigate next icon"
            />
            </div>
          </div>
        </OwlCarousel>
      </section> */}

      <EventsSlider />

      <Awards />

      <TvRadio image={LandingVideoOne} />

      <Contact />

      <SocialMedia />

      <Footer />
    </div>
  );
}

export default AuthenticatedHome;
