import React, { useState, useEffect, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import { artistGetBookings } from "../../../../../api/auth";
import { userStore } from "../../../../../stores";
import { AiOutlineArrowLeft } from "react-icons/ai";
const SetBookableArtistModal = lazy(() =>
  import("../../../../modals/setBookableArtistModal")
);

function ArtistBookings() {
  const [loading, setLoading] = useState();
  const [bookings, setBookings] = useState([]);
  const [bookableModal, setBookableModal] = useState();
  const user = userStore((state) => state.user);
  useEffect(() => {
    const getContent = async () => {
      setLoading(true);
      const res = await artistGetBookings();
      setLoading(false);
      if (res && res.status == 200) {
        setBookings(res.data.bookings);
      }
    };
    getContent();
  }, []);
  return (
    <div>
      <div>
        <div className="flex md:pt-0 pt-10 text-gray-200 justify-between z-0">
          <div className="md:flex hidden items-center p-4 px-8 space-x-4">
            <div className="md:relative shadow-md cursor-pointer">
              {user &&
                (user.image ? (
                  <img
                    className="rounded w-40 h-40 object-cover"
                    src={
                      user?.image
                        ? user?.image?.includes("cloudinary") ||
                          !user?.image?.includes("prod")
                          ? "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                          : user?.image
                        : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                    }
                    alt="Profile pic"
                  />
                ) : (
                  <img
                    className="rounded w-40 h-40"
                    src="https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                    alt="Profile pic"
                  />
                ))}
            </div>
            <div className="flex flex-col">
              <div className="text-2xl font-extrabold ">
                {user ? user.names || "No Name" : "No Name"}
              </div>
              <div className="pl-2">{user && user.email}</div>
            </div>
          </div>
          <div className="p-4 px-8 md:flex hidden  items-start space-x-4">
            <div
              className="px-6 py-2 bg-transparent border-2 font-semibold border-gray-200 rounded-full hover:underline cursor-pointer"
              onClick={() => setBookableModal(true)}
            >
              Bookable Status
            </div>
            {user && (
              <Link
                to={"/dashboard/artist"}
                className="px-6 py-2 bg-transparent border-2 font-semibold border-gray-200 rounded-full hover:underline cursor-pointer"
              >
                Back to Profile
              </Link>
            )}
            {!user && (
              <div className="px-6 py-2 bg-transparent border-2 font-semibold border-gray-200 rounded-full hover:underline cursor-pointer">
                Create Account
              </div>
            )}
          </div>
          <div className="p-2 px-2 flex md:hidden w-full  items-center justify-between space-x-4">
            <Link to={"/dashboard/artist"} className="">
              <AiOutlineArrowLeft className=" text-xl" />
            </Link>
            <button
              className="px-4 py-1 mr-2 rounded-full font-black text-sm  text-white"
              style={{
                background: `linear-gradient(to right ,${"#2FAFF2"}, ${"#695FEF"})`,
              }}
              onClick={() => setBookableModal(true)}
            >
              Booking Status
            </button>
          </div>
        </div>
      </div>
      <div className=" text-white  font-semibold py-2 text-lg">Bookings</div>
      <table className="min-w-full table-fixed divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5  pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Title
            </th>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Location
            </th>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Time
            </th>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Offer
            </th>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Payment Status
            </th>
            <th
              scope="col"
              className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
            >
              Approval Status
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {!loading &&
            bookings?.length > 0 &&
            bookings.map((booking) => (
              <tr>
                <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
                  {booking?.title}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
                  {booking?.location}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.time}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  <span className=" text-green">{booking?.currency}</span>{" "}
                  {booking?.offer}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.paymentStatus}
                </td>
                <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
                  {booking?.status}
                </td>
              </tr>
            ))}
          {loading && <BookingsListLoading />}
          {bookableModal && (
            <Suspense fallback={<div>Loading</div>}>
              <SetBookableArtistModal closeHandler={setBookableModal} />
            </Suspense>
          )}
        </tbody>
      </table>
    </div>
  );
}
export const BookingsListLoading = () => {
  return Array(5)
    .fill(1)
    .map((el, index) => {
      return (
        <tr>
          <td className="whitespace-nowrap pl-2 pr-3 text-left py-4 text-sm text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
          <td className="whitespace-nowrap py-4 pr-3 text-left  capitalize text-sm font-medium text-white">
            <div className="w-16 h-4 bg-gray-300 animate-pulse rounded-md"></div>
          </td>
        </tr>
      );
    });
};
export default ArtistBookings;
